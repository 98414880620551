import React from "react"

import telegramSvg from "../../images/social/telegram.svg"
import twitterSvg from "../../images/social/twitter.svg"
import discordSvg from "../../images/social/discord.svg"
import redditSvg from "../../images/social/reddit.svg"
import coinmarketcapSvg from "../../images/social/coinmarketcap.svg"
import telegramSvgHover from "../../images/social/telegramhover.svg"
import twitterSvgHover from "../../images/social/twitterhover.svg"
import discordSvgHover from "../../images/social/discordhover.svg"
import redditSvgHover from "../../images/social/reddithover.svg"
import coinmarketcapSvgHover from "../../images/social/coinmarketcaphover.svg"

import HoverImage from "react-hover-image";

const social = [
  {
    name: "Telegram",
    href: "https://t.me/odinprotocol",
    icon: telegramSvg,
    iconhover: telegramSvgHover
  },
  {
    name: "Twitter",
    href: "https://twitter.com/odinprotocol",
    icon: twitterSvg,
    iconhover: twitterSvgHover
  },
  {
    name: "Discord",
    href: "https://discord.gg/BrBfcgmnk2",
    icon: discordSvg,
    iconhover: discordSvgHover
  },
  {
    name: "Reddit",
    href: "https://www.reddit.com/r/OdinProtocol",
    icon: redditSvg,
    iconhover: redditSvgHover
  },
  {
    name: "CMC",
    href: "https://coinmarketcap.com/currencies/odin-protocol/",
    icon: coinmarketcapSvg,
    iconhover: coinmarketcapSvgHover
  }
]

export default function SocialLinks() {
  return (
    <div className="flex justify-center space-x-3 md:order-2">
      {social.map(item => (
        <a
          key={item.name}
          title={item.name}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:bg-borderSecondary"
        >
        <HoverImage className="h-8 w-8" src={item.icon} hoverSrc={item.iconhover}
         
          />
        </a>
      ))}
    </div>
  )
}
