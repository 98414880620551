import React from "react"
import Banner from "../elements/Banner"
import Footer from "./Footer"
import Header from "./Header"
import '../../styles/layout.css'

export default function Layout({ children }) {
  return (
    <div className="w-full h-full min-h-screen mx-auto font-customRegular text-textSecondary gradient-bg">
      <Banner />
      <Header />
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-10">
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}
