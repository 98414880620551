import React from "react"
import '../../styles/banner.css';
export default function Banner() {
  return (
    <div className="relative bg-blue gradient-background">
      <div className="max-w-3xl mx-auto py-2 px-2 sm:px-4 lg:px-6">
        <div className="px-4 text-center sm:px-12">
          <p className="text-white text-xs">
            <a className="inline" href="https://frontier.osmosis.zone/?from=OSMO&to=ODIN" target="_blank" rel="noopener noreferrer">
              {/* <b className="text-base">🧪 Trade $ODIN on Osmosis Frontier 🧪</b> */}
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
