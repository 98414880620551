import React from "react"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Navbar({ sections, links }) {
  return (
    <div className="hidden relative lg:flex pb-5 border-b border-t border-borderSecondary sm:pb-0">
      <div className="flex w-full max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-10 md:justify-between">
        <div className="flex mt-4">
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {sections.map(tab => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current
                      ? "border-textPrimary text-textPrimary"
                      : "border-transparent hover:text-textPrimary hover:border-textPrimary",
                    "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>

        <div className="flex mt-4">
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {links.map(tab => (
                <a
                  key={tab.name}
                  href={tab.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classNames(
                    tab.current
                      ? "border-textPrimary text-textPrimary"
                      : "border-transparent hover:text-textPrimary hover:border-textPrimary",
                    "whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}
