import React from "react"
import logoSvg from "../../images/logo.svg"
import SocialLinks from "../elements/SocialLinks"

const links = [
  {
    name: "Docs",
    href: "https://geodb.gitbook.io/odin-protocol/getting-started",
    current: false,
  },
  { name: "Mainnet", href: "http://mainnet.odinprotocol.io/", current: false },
  { name: "ODIN Explorer", href: "https://scan.odinprotocol.io/", current: false },
]

export default function Footer() {
  return (
    <footer aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="flex flex-col justify-center text-center w-full border-b border-t border-borderSecondary">
        <div className="flex flex-col justify-center text-center lg:flex-row lg:justify-between w-full max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-10 py-10">
          <div className="flex w-full mb-12 justify-center lg:justify-start lg:mb-0">
            <img className="h-6 w-auto sm:h-8" src={logoSvg} alt="ODIN" />
          </div>
          <div className="flex w-full mb-10 justify-center lg:mb-0">
            <nav className="flex space-x-8">
              {links.map(tab => (
                <a
                  key={tab.name}
                  href={tab.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"whitespace-nowrap px-1 text-sm"}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
          <div className="flex w-full justify-center lg:justify-end">
            <SocialLinks />
          </div>
        </div>
      </div>
      <div className="m-6">
        <p className="text-sm text-textPrimary text-center">
          Copyright © {new Date().getFullYear()}. All rights reserved. Odin Protocol.
        </p>
      </div>
    </footer>
  )
}
